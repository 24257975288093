import React from 'react';
import styled from 'styled-components';
import {Tabs, message} from 'antd';
import GenericForm from 'rev.sdk.js/Generic/Form';
import * as AppActions from '../../AppActions';
import * as AppActionsEx from '../../AppActions/custom';
import DataJson from '../../../data.json';

function RequestPage(props) {
  return (
    <Wrapper>
      <div className="container">
        <h1 className="title">註冊新帳號(1/3)</h1>
        <div className="description">
          <b>
            申請入會完成，請至「個人資訊」填寫個人資料，並依規定繳交相關證明資料。
          </b>
        </div>
        <div>
          <GenericForm
            instance={{
              register_type: 'lawyer',
            }}
            schema={MEMBER_JSON.schema}
            uiSchema={MEMBER_JSON.uiSchema}
            errorSchema={MEMBER_JSON.errorSchema}
            onSubmit={onSubmitMember}
          />
        </div>
      </div>
    </Wrapper>
  );
}

const MEMBER_JSON = {
  schema: {
    title: '',
    type: 'object',
    required: ['username', 'register_type', 'name', 'phone', 'email'],
    properties: {
      register_type: {
        type: 'string',
        title: '申請類型',
        enum: [
          // 'lawyer',
          // 'special',
          // 'foreign_lawyer',
          'twba_member',
          'intern',
          'extra_member',
        ],
        enumNames: [
          // '一般會員',
          // '特別會員',
          // '外國法事務律師',
          '全律會會員',
          '實習律師',
          '非律師外部會員',
        ],
      },
      username: {
        type: 'string',
        title: '身分證字號',
        pattern: '^[A-Z]{1}[0-9]{9}$',
      },
      name: {type: 'string', title: '姓名'},
      phone: {
        type: 'string',
        title: '手機號碼',
        pattern: '^09[0-9]{8}$',
      },
      email: {
        type: 'string',
        title: '電子郵件信箱',
        pattern: '^\\S+@\\S+\\.\\S+$',
      },
    },
    dependencies: {
      register_type: {
        oneOf: [
          {
            properties: {
              register_type: {enum: ['lawyer']},
            },
          },
          {
            properties: {
              register_type: {enum: ['special']},
            },
          },
          {
            properties: {
              register_type: {enum: ['foreign_lawyer']},
            },
          },
          {
            properties: {
              register_type: {enum: ['extra_member']},
            },
          },
          {
            required: ['advisor_name'],
            properties: {
              register_type: {enum: ['intern']},
              advisor_name: {
                type: 'string',
                title: '指導律師姓名',
              },
            },
          },
          {
            required: ['BAR_ID', 'birthday'],
            properties: {
              register_type: {enum: ['twba_member']},
              BAR_ID: {
                type: 'integer',
                title: '所屬律師公會',
                enum: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
                enumNames: [
                  '宜蘭',
                  '基隆',
                  '桃園',
                  '新竹',
                  '苗栗',
                  '台中',
                  '彰化',
                  '雲林',
                  '嘉義',
                  '南投',
                  '台南',
                  '高雄',
                  '屏東',
                  '台東',
                  '花蓮',
                ],
              },
              birthday: {
                type: 'string',
                title: '生日',
                format: 'date',
              },
            },
          },
        ],
      },
    },
  },
  uiSchema: {
    register_type: {
      'ui:widget': 'radio',
    },
    'rev-line-0': {
      'ui:widget': 'rev-line',
      'ui:options': {
        label: false,
      },
    },
  },
  errorSchema: {
    username: {
      pattern: '請輸入正確身分證字號格式, 首字請用大寫',
      required: '此為必填欄位',
    },
    advisor_name: {
      required: '此為必填欄位',
    },
    register_type: {
      required: '此為必填欄位',
    },
    name: {
      required: '此為必填欄位',
    },
    phone: {
      pattern: '請輸入正確的手機格式，以09為開頭',
      required: '此為必填欄位',
    },
    email: {
      pattern: '請輸入正確的email格式',
      required: '此為必填欄位',
    },
  },
};

const onSubmitMember = async (formData, extValue) => {
  const {
    username,
    register_type,
    advisor_name,
    phone,
    name,
    email,
    BAR_ID,
    birthday,
  } = formData;

  const goOn = window.confirm(
    `確認使用${phone}註冊? 我們將發送認證碼至該手機。`,
  );

  if (!goOn) {
    AppActions.setLoading(false);
    return;
  }

  AppActions.setLoading(true);
  try {
    const resp = await AppActionsEx.smsRegisterRequest({
      username,
      id_card_number: username,
      register_type,
      phone,
      name,
      email,
      advisor_name,
      BAR_ID,
      birthday,
    });

    const {state, expired_timestamp} = resp;

    const formQueryStr = Object.keys(formData)
      .reduce((acc, k) => {
        return acc + `${k}=${encodeURIComponent(formData[k])}&`;
      }, '')
      .slice(0, -1);

    const nextPath = `/register/confirm/?${formQueryStr}&state=${state}&expired=${expired_timestamp}`;

    AppActions.navigate(nextPath);
  } catch (err) {
    console.warn(err);

    if (err?.response.error === 'username_exist') {
      message.error('您已註冊，請嘗試登入，若有問題請與公會聯繫。');
    } else {
      message.error('發生錯誤! 請稍後再試。');
    }
  } finally {
    AppActions.setLoading(false);
  }
};

const Wrapper = styled.div`
  padding-top: var(--topNavBarHeight);

  & > .container {
    max-width: 650px;
    width: 100%;
    min-height: 80vh;

    margin: 0 auto;
    padding: 60px 20px;

    & > .title {
      margin-bottom: 15px;
      font-weight: 500;
      color: var(--primaryColor);
    }

    & > .description {
      margin-bottom: 20px;
      font-size: 0.95rem;
      line-height: 1.9rem;

      & > b {
        margin-left: 3px;
        margin-right: 3px;
        padding-bottom: 2px;
        border-bottom: 1px solid #000000;
      }

      & > a {
        margin-right: 5px;
        margin-left: 5px;
        padding-bottom: 2px;
        border-bottom: 1px solid var(--primaryColor);
      }
    }
  }

  & .text {
    margin-bottom: 10px;
    line-height: 1.85rem;
    font-size: 0.95rem;

    & .link {
      margin-right: 5px;
      margin-left: 5px;
    }

    .highlight {
      margin-right: 5px;
      margin-left: 5px;
      font-weight: 600;
      color: var(--secondColor);
    }
  }
`;

export default RequestPage;
